<template>
  <div className="game-zone">
    <div class="game-wrapper">
      <div class="card-container" @click="state.select(i)" v-for="(i,index) in state.gameTable" v-bind:key="i.index">
        <div class="game-card" v-bind:class="{ selected: i.selected, 'non-selection': i.selected===false && state.status === 4,
        'diamond-not-found': i.type===1 && i.selected === false }">

          <div class="number-label" v-bind:class="{'found': showable(i)}">{{index+1}}</div>
          <div v-if="i.status===1 || state.status === 4 || state.status === 5">
            <div class="result-container"
                 v-bind:class="{ selected: i.status===1}">
              <img class="diamond" :src="diamond" v-if=" showable(i)"/>
              <div class="bomb-effect" v-if="showable(i)">
                <img class="effect" :src="effect1"/>
                <img class="effect" :src="effect2"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PayTable class="payTable-board" :gameTable="state.gameTable" :payTable="selectedPayTable" :gameState="state.status" />
      <div class="info-board-container" v-if="selectedGameTable===0">
        <div class="info-board">Select 1 - 10 nubmers to play</div>
      </div>
      <div class="winner-display" v-if="state.isWinner">
        <span class="multip">{{ state.multiplier | multiplier}}x</span>
        <span><span class="sperator"></span></span>
        <span class="winned-price">{{ formatPrice(state.winAmount) }}</span>
      </div>
    </div>
  </div>
</template>

<script>

import PayTable from "./PayTable";
export default {
  name: 'Game',
  components: {PayTable},
  async mounted() {
    if (!window.mimikoClient.Token) {
      return false;
    }
    let gameTable = [];
    for (let i = 0; i < 40; i++) {
      gameTable.push({
        index: i,
        selected: false,
        status: 0,
        type: 0
      })
    }
    this.state = this.$attrs.state;
    this.state.gameTableUpdate(gameTable);
    // get info
    let data = {
          "Token":window.mimikoClient.Token,
          "SiteId":Number(window.mimikoClient.SiteId),
          "CurrencyId":Number(window.mimikoClient.CurrencyId),
      }; 
    const rawResponseStart = await fetch(this.state.endpoint + '/info', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const responseStart = await rawResponseStart.json();
    if (responseStart.Details) {
      this.state.paytable = responseStart.Details;
    } else {
      this.state.errorMsg = 'Paytable can not loaded.';
      this.state.isError = true;
    }

    this.state.setGameState(1);
    /*
    let data = {
      Token: window.mimikoClient.Token,
      SiteId: Number(window.mimikoClient.SiteId),
      CurrencyId:   Number(window.mimikoClient.CurrencyId),
    }*/


  },
  filters:{
    multiplier:function (n){
      return Number(n).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
  },
  computed:{
    selectedPayTable:function (){
      if(this.state.paytable){

        return this.state.paytable[this.state.riskLevel];
      }
      return  null;
    },
    selectedGameTable:function (){
      if(!this.state.gameTable){
        return  0;
      }
      var count = this.state.gameTable.filter(val => {
        return val.selected
      })
      return count.length;
    }
  },
  methods:{
    formatPrice: function (x){
      return Number(x).toFixed(this.state.numberLimit);
    },
    showable: function (i){
      return i.type===1 && i.selected && this.state.status === 4 ||  i.type===1 && i.selected && this.state.status === 5
    }
  },
  data() {
    return {
      /**
       *  state.status
       *  0 init (bu kısımda oyun var mı hesabı yapılacak)
       *  1 ide
       *  2 loading
       *  4 oyun bitti
       */
      diamond: require('../assets/diamond.svg'),
      effect1: require('../assets/effect1.svg'),
      effect2: require('../assets/effect2.svg'),
      state: {}
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.number-label {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 24px;
  font-size: 20px;
  font-weight: 900;
  text-shadow: 2px 0 2px #000;
}
.selection,
img.mini-display,
.game-card {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.game-card {
  background: #2f4553;
  display: inline-block;
  width: 85px;
  height: 85px;
  top: -7px;
  left: 0;
  position: absolute;
  border-radius: 6px;

}
.result-container.non-selection > img {
  opacity: 0.4;
  padding: 25px;
}

.game-card:hover {
  top: 0;
  cursor: pointer;

  background: #7e9eb3;
}
.game-card.diamond-not-found,
.game-card.non-selection {
  background: #1a2c38;
  top:0;
}
.game-card.selected {
  top: 0;
  background: #962eff;
}

.card-container {
  background: #213743;
  display: inline-block;
  width: 85px;
  height: 85px;
  margin: 10px;
  position: relative;
  box-shadow: 0 3px 2px rgb(0 0 0 / 10%);
  border-radius: 6px;
}

@keyframes effect {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.winner-display {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 100px;
  width: 250px;
  background: #15232c;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  border: 4px solid #15e703;
}

.winner-display > * {
  display: flex;
  flex: 1;
}

span.winned-price {
  color: #15e703;
  font-size: 16px;
}


.diamond-not-found .number-label {
  color: #e91e63;
}
.number-label.found {
  color: #607d8b;
  text-shadow: 0 0 3px #8bc34a;
}
span.sperator {
  display: block;
  background: #2f4553;
  width: 25px;
  height: 3px;
}

span.multip {
  font-size: 26px;
  font-weight: 900;
}

@keyframes selectEffect {
  0% {
    padding: 50px;
  }
  25% {
    padding: 0
  }
  50% {
    padding: 15px
  }
  75% {
    padding: 20px
  }
  100% {
    padding: 15px
  }
}

.diamond {
  animation-name: selectEffect;
  animation-duration: 1s;
}
.game-card.non-selection.diamond-not-found {
  background: rgb(0 0 0 / 52%);
  top: 0;
}
.bomb-effect {

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.bomb-effect > * {
  position: absolute;
  left: 5px;
  top: 0;

  opacity: 0;
  animation-name: effect;
  animation-duration: 0.8s;
}

.bomb-effect > *:last-child {
  animation-delay: 0.4s;
  left:-20px;
}

img.diamond,
img.bomb {
  padding: 15px;
}

img.bomb {
  position: absolute;
  left: 0;
  top: -5px;
}

.game-wrapper {
  padding: 0;
  background: rgb(0 0 0 / 20%);
  position: relative;
}
.info-board {
  background: #f44336;
  border-radius: 5px;
  padding: 5px;
  color: #fff;
}

.info-board-container {
  padding: 20px 50px;
  box-sizing: border-box;
}
@media screen and (max-width: 1100px) {
  .game-wrapper {
    padding: 0;
  }
  div#app > div:last-child{
    flex:11;
  }
}
</style>
