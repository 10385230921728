<template>
  <div class="payTable-layout">
    <div v-for="i in selectedGameTable" v-bind:key="i.index" class="selection"
         v-bind:class="{ 'hide': selectedPayTable[i-1].Multiplier==0, 'won':foundedCount>=i }" >
      <span class="selection-multiplier">{{selectedPayTable[i-1].Multiplier|multiplier}}<span>x</span></span>
      <span class="selection-count">{{i}}x<img :src="diamondIcon" class="mini-display"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PayTable',
  props:{
    gameState:{
      type: Number,
      require: true
    },
    gameTable:{
      type: Array,
      require: true
    },
    payTable:{
      type: Object,
      require: true
    }
  },
  data() {
    return {
      betStep: 0.000001,
      numberLimit: 14,
      bombIcon: require('../assets/miniBomb.svg'),
      diamondIcon: require('../assets/diamond.svg'),
    }
  },
  filters:{
    multiplier:function (n){
      return Number(n).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
  },
  computed:{
    foundedCount: function (){
      var c = this.gameTable.filter(val => {
        return val.type===1 && val.selected
      })
      return c.length;
    },
    selectedPayTable:function (){
      let n = this.payTable.Selections[this.selectedGameTable-1].MatchMultipliers;
      if(n){
        return  n;
      }
      return [];
    },
    selectedGameTable:function (){
      if(!this.gameTable){
        return  0;
      }
      var count = this.gameTable.filter(val => {
        return val.selected
      })
      return count.length;
    }
  },
  mounted() {

  },
  methods: {
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.payTable-layout {
  display: flex;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  overflow-x: auto;
}
.selection.hide {
  display: none;
}
.selection {flex: 1;display: flex;text-align: center;justify-content: center;flex-direction: column;background: #2f4553;margin: 10px;border-radius: 4px;padding: 5px 2px;}

img.mini-display {
  width: 15px;
  display: inline-block;
  vertical-align: middle;
  padding: 0 3px;
  margin-top: -4px;
  filter: grayscale(100);
}

span.selection-count {
  font-size: 14px;
}

.selection-multiplier {
  color: #00e701;
  margin-bottom: 10px;
  font-size: 20px;
  text-shadow: 1px 1px #000;
}

.selection-multiplier>span {
  top: -3px;
  position: relative;
  font-size: 13px;
}
.selection.won {
  background: #22524eed;
}

.won img.mini-display {
  filter: grayscale(0);
}
</style>
